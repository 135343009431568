<template>
  <item-edit-view
    title="Изображение"
    :loading="loading"
  >
    <template v-slot:right>
      <v-btn
        right
        color="success"
        :loading="saving"
        @click.prevent="save"
      >
        <v-icon small>mdi-content-save</v-icon>
        Сохранить
      </v-btn>
    </template>
    <v-card>
      <v-form v-if="!loading && item">
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tab key="info">Инфо</v-tab>
          <v-tab key="crop">Обрезка</v-tab>
          <v-tab key="gravity">Фокус</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="info">
            <v-card flat>
              <v-card-text>
                <div>
                  <v-text-field
                    v-model.trim="item.title"
                    label="Заголовок"
                  ></v-text-field>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="crop">
            <v-card flat>
              <v-card-text>
                <div>
                  <v-checkbox
                    v-model="item.isCropEnabled"
                    label="Обрезать"
                  ></v-checkbox>
                </div>
                <div>
                  <div v-if="item.isCropEnabled">
                      <vue-cropper
                          ref='crop'
                          :crop="onCrop"
                          :ready="onReadyCrop"
                          :guides="true"
                          :view-mode="2"
                          drag-mode="crop"
                          :auto-crop-area="0.5"
                          :zoomable="false"
                          :aspect-ratio="1/1"
                          :min-container-width="250"
                          :min-container-height="180"
                          :background="true"
                          :rotatable="false"
                          :src="item.originalUrl"
                      >
                      </vue-cropper>
                  </div>
                  <figure v-else>
                    <img :src="item.originalUrl" class="image_max-height"/>
                  </figure>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="gravity">
            <v-card flat>
              <v-card-text>
                <v-radio-group v-model="item.gravity">
                  <div>
                    <v-radio
                      :key="smartGravity"
                      :value="smartGravity"
                      label="Автоматический"
                    ></v-radio>
                  </div>
                  <div>
                    <div class="gravity">
                      <figure class="gravity__back">
                        <img :src="item.originalUrl" class="image_max-height"/>
                        <div class="gravity__variants">
                          <div class="gravity__variant" v-for="gravity in gravities">
                            <v-radio
                              :key="gravity"
                              :value="gravity"
                            ></v-radio>
                          </div>
                        </div>
                      </figure>
                    </div>
                  </div>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card>
  </item-edit-view>
</template>

<style lang="scss" scoped>
  .image_max-height {
    max-height: 600px;
  }

  img {
    max-width: 100%;
  }

  .has_avatar {
    margin-bottom: 1em;
  }

  .gravity {
    &__back {
      position: relative;
      z-index: 1;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        opacity: 0.8;
        z-index: 2;
      }
    }
    &__variants {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 3;
    }
    &__variant {
      display: inline-block;
      margin: 0;
      border: 1px dashed #ccc;
      border-collapse: collapse;
      width: 33.33%;
      height: 33.33%;
      text-align: center;
      &:after {
        content: "";
        display: inline-block;
        width: 0;
        height: 100%;
        vertical-align: middle;
      }
      > input {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .title-action {
    display: flex;
    justify-content: space-between;
  }
</style>

<script>
import { required } from 'vuelidate/lib/validators'
import photosService from '../../services/photos'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import isUndefined from 'lodash/isUndefined'

export default {
  mixins: [createOrUpdateViewMixin(photosService, {
    gravities: photosService.gravities,
    smartGravity: photosService.smartGravity,
    isCropParamsInited: false,
    tab: null,
  })],
  validations: {
    item: {
      gravity: {
        required
      }
    }
  },
  watch: {
    'item.isCropEnabled': function (enabled, oldEnabled) {
      if (enabled === oldEnabled || !enabled) {
        return
      }
      setTimeout(() => {
        this.updateCropParams()
      }, 0)
    },
    'tab': function (value) {
      if (value === 2) {
        setTimeout(() => {
          let imgParams = this.$el
            .querySelector('.gravity__back img')
            .getBoundingClientRect()

          let variants = this.$el.querySelector('.gravity__variants')
          variants.style.height = `${imgParams.height}px`
          variants.style.width = `${imgParams.width}px`
        }, 100)
      }
    }
  },
  methods: {
    onCrop (event) {
      if (!this.isCropParamsInited) {
        return
      }
      this.item.cropParams = this.$refs.crop.getData(true)
    },
    onReadyCrop () {
      setTimeout(() => {
        this.updateCropParams()
        this.isCropParamsInited = true
      }, 0)
    },
    updateCropParams (enabled) {
      if (this.item.cropParams && !isUndefined(this.$refs.crop)) {
        this.$refs.crop.setData({
          x: Math.round(this.item.cropParams.x),
          y: Math.round(this.item.cropParams.y),
          width: Math.round(this.item.cropParams.width),
          height: Math.round(this.item.cropParams.height)
        })
      }
    }
  }
}
</script>
